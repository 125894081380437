export const setPFToken = (token: string) =>
  localStorage.setItem('PF_ACCESS_TOKEN', token)

const getPFToken = () => localStorage.getItem('PF_ACCESS_TOKEN')

export const getValidPFToken = () => {
  const token = getPFToken()
  try {
    const decoded = JSON.parse(atob(token?.split('.')[1] || ''))
    if (decoded.exp && decoded.exp * 1000 < Date.now() + 30 * 1000) {
      clearPFToken()
    }
  } catch (e) {
    clearPFToken()
  }

  return getPFToken()
}

export const clearPFToken = () => {
  localStorage.removeItem('PF_ACCESS_TOKEN')
}
export const getUserId = () => {
  const token = localStorage.getItem('PF_ACCESS_TOKEN')
  if (!token?.split('.')[1]) return undefined
  const decoded = JSON.parse(atob(token?.split('.')[1]))

  return decoded?.['id']
}
export const getUserEmail = () => {
  const token = localStorage.getItem('PF_ACCESS_TOKEN')
  if (!token?.split('.')[1]) return undefined
  const decoded = JSON.parse(atob(token?.split('.')[1]))
  return decoded?.['email']
}
